<template>
  <v-card :loading="loading" flat>
    <v-card-text>

      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <td>
              <v-btn @click="loadData" color="primary" small fab>
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <th class="text-center">File Name</th>
            <th class="text-center">To Be Submitted By</th>
            <th class="text-center">Created At</th>
            <th class="text-center">Updated At</th>
            <th class="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in unSubmittedFiles">
            <UnSubmittedFileRow :key="item.id" :un-submitted-file-row="item" @updateComplete="loadData" @downloadComplete="loadData">
            </UnSubmittedFileRow>
          </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ccrsApi from "@/lib/ccrs-api";

export default {
  name: "unSubmittedFiles.vue",
  components: {
    UnSubmittedFileRow: () => import('./UnsubmittedFileRow'),
  },
  mounted() {
    this.loadData();
  },
  data: () => ({
    loading: false,
    loadingProcess: false,
    unSubmittedFiles: [],
    submittedBy: '',
    processResponse: [],
  }),
  methods: {
    async loadData() {
      this.loading = true;
      let query = await ccrsApi.get('/v2/labNewFiles');
      this.unSubmittedFiles = query.data;
      this.loading = false;
    },
  }
}
</script>

<style scoped>

</style>